import styled, { css } from 'styled-components'

import { tablet } from '../mixins'

type Props = {
  noPadding?: boolean
  noPaddingTablet?: boolean
}

const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  max-width: 1264px;
  margin: 0 auto;

  ${({ noPadding, noPaddingTablet }) =>
    !noPadding &&
    css`
      padding-left: 32px;
      padding-right: 32px;
      ${tablet(
        noPaddingTablet
          ? css`
              padding-left: 0;
              padding-right: 0;
            `
          : css`
              padding-left: 24px;
              padding-right: 24px;
            `,
      )}
    `}
`

export default Container
