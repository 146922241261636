import { getData as getCountries } from 'country-list'
import { US_STATES_AND_TERRITORIES, CANADIAN_TERRITORIES } from 'us-regions'

const { keys } = Object

interface Territory {
  code: string
  name: string
}

export interface Country extends Territory {
  postalCodeName?: string
  states?: UtilityArray<Territory>
}

const sortByName = function (a: Country, b: Country) {
  return a.name > b.name ? 1 : -1
}

export const findByCode = function <T extends Territory>(
  list: T[],
  lookupCode: string,
) {
  return list.find(({ code }) => code === lookupCode)
}

export const isValidState = function (
  country: Country,
  state: string,
): boolean {
  return !!country.states?.some(({ code }) => code === state)
}

declare class UtilityArray<T> extends Array<T> {
  findByCode: typeof findByCode
}

const buildStates = (stateByCode: Record<string, string>) =>
  keys(stateByCode)
    .map((code) => ({ code, name: stateByCode[code] }))
    .sort(sortByName)

const countries = getCountries().sort(sortByName) as Country[]

// Set postal code name for USA
// @ts-ignore
findByCode(countries, 'US').postalCodeName = 'Zip Code'
// Set postal code name for UK/GB
// @ts-ignore
findByCode(countries, 'GB').postalCodeName = 'Postcode'

// Set states on USA
// @ts-ignore
findByCode(countries, 'US').states = buildStates(US_STATES_AND_TERRITORIES)
// Set states on Canada
// @ts-ignore
findByCode(countries, 'CA').states = buildStates(CANADIAN_TERRITORIES)

export const supportedAmericas = ['US', 'CA']
//TODO: Remove after BE if we re-enable self-service
export const supportedEurope = [
  'GB',
  'FR',
  'BE',
  'AT',
  'CH',
  'CY',
  'DK',
  'EE',
  'FI',
  'DE',
  'GR',
  'HR',
  'IE',
  'IL',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]
export const supportedCountries = supportedAmericas.concat(supportedEurope)

export default countries
