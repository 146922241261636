import styled, { css } from 'styled-components'

import { mobile } from './mixins'
import { Color } from './theme'

export interface DefaultProps {
  noColor?: boolean
  noResponsive?: boolean
}

const defaultStyle = ({ noColor }: DefaultProps) => css`
  padding: 0;
  margin: 0;
  ${!noColor &&
  `
    color: ${Color.BLACK};
  `}
`

export const regularStyle = css`
  font-family: 'Indivisible-Regular';
`

const boldStyle = css`
  font-family: 'Indivisible-Bold';
`

const semiboldStyle = css`
  font-family: 'Indivisible-SemiBold';
`

const mediumStyle = css`
  font-family: 'Indivisible-Medium';
`

export const heading1Style = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${boldStyle}
  font-style: normal;
  font-size: 64px;
  line-height: 115%;
  letter-spacing: -1px;

  ${!props.noResponsive &&
  mobile(heading2Style({ ...props, noResponsive: true }))}
`

export const heading2Style = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${boldStyle}
  font-style: normal;
  font-size: 40px;
  line-height: 120%;

  ${!props.noResponsive &&
  mobile(heading3Style({ ...props, noResponsive: true }))}
`

export const heading3Style = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${semiboldStyle}
  font-style: normal;
  font-size: 24px;
  line-height: 145%;

  ${!props.noResponsive &&
  mobile(bodySemiboldStyle({ ...props, noResponsive: true }))}
`

export const subheadRegularStyle = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${regularStyle}
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 145%;

  ${!props.noResponsive &&
  mobile(bodyRegularStyle({ ...props, noResponsive: true }))}
`

export const subheadMediumStyle = (props: DefaultProps) => css`
  ${subheadRegularStyle(props)}
  ${mediumStyle}

  ${!props.noResponsive &&
  mobile(bodyMediumStyle({ ...props, noResponsive: true }))}
`

export const bodyRegularStyle = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${regularStyle}
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;

  ${!props.noResponsive &&
  mobile(calloutRegularStyle({ ...props, noResponsive: true }))}
`

export const bodySemiboldStyle = (props: DefaultProps) => css`
  ${bodyRegularStyle(props)}
  ${semiboldStyle}

  ${!props.noResponsive &&
  mobile(calloutSemiboldStyle({ ...props, noResponsive: true }))}
`

export const bodyMediumStyle = (props: DefaultProps) => css`
  ${bodyRegularStyle(props)}
  ${mediumStyle}
`

export const calloutRegularStyle = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${regularStyle}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;

  ${!props.noResponsive &&
  mobile(captionRegularStyle({ ...props, noResponsive: true }))}
`

export const calloutSemiboldStyle = (props: DefaultProps) => css`
  ${calloutRegularStyle(props)}
  ${semiboldStyle}
  ${mobile(semiboldStyle)}
`

export const calloutMediumStyle = (props: DefaultProps) => css`
  ${calloutRegularStyle(props)}
  ${mediumStyle}
`

export const captionRegularStyle = (props: DefaultProps) => css`
  ${defaultStyle(props)}
  ${regularStyle}
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
`

export const captionSemiboldStyle = (props: DefaultProps) => css`
  ${captionRegularStyle(props)}
  ${semiboldStyle}
  ${mobile(semiboldStyle)}
`

export const Heading1 = styled.h1<DefaultProps>`
  ${heading1Style}
`
export const Heading2 = styled.h2<DefaultProps>`
  ${heading2Style}
`
export const Heading3 = styled.h3<DefaultProps>`
  ${heading3Style}
`
export const SubheadRegular = styled.p<DefaultProps>`
  ${subheadRegularStyle}
`
export const SubheadMedium = styled.p<DefaultProps>`
  ${subheadMediumStyle}
`
export const BodyRegular = styled.p<DefaultProps>`
  ${bodyRegularStyle}
`
export const BodySemibold = styled.p<DefaultProps>`
  ${bodySemiboldStyle}
`
export const BodyMedium = styled.p<DefaultProps>`
  ${bodyMediumStyle}
`
export const CalloutRegular = styled.p<DefaultProps>`
  ${calloutRegularStyle}
`
export const CalloutSemibold = styled.p<DefaultProps>`
  ${calloutSemiboldStyle}
`
export const CaptionRegular = styled.p<DefaultProps>`
  ${captionRegularStyle}
`

export type Typography =
  | typeof Heading1
  | typeof Heading2
  | typeof Heading3
  | typeof SubheadRegular
  | typeof SubheadMedium
  | typeof BodyRegular
  | typeof BodySemibold
  | typeof BodyMedium
  | typeof CalloutRegular
  | typeof CalloutSemibold
  | typeof CaptionRegular

export const typographyClassName = (typography: Typography): string =>
  typography.toString().replace('.', '')
