import { Container } from '..'
import styled, { css } from 'styled-components'

import { maxScreenWidth, minScreenWidth } from '../../mixins'
import { Color } from '../../theme'
import { bodyMediumStyle, bodySemiboldStyle } from '../../typography'

export const COLLAPSED_MENU_MAX_WIDTH = 1_050

export const Ctas = styled.div`
  display: flex;
  flex-direction: row;

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    flex-direction: column;
    padding: 24px;
    width: 100%;
    button {
      width: 100%;
      box-shadow: none;
      ${bodyMediumStyle({ noResponsive: true, noColor: true })}
    }
  `)}
`

export const Pages = styled(Container)<{ isExpanded: boolean }>`
  order: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.15s ease-in-out;

  ${({ isExpanded }) =>
    maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
      justify-content: start;
      top: 56px;
      margin-top: -64%;
      max-width: 100%;
      width: 100%;
      left: 0;
      right: 0;
      visibility: hidden;
      flex-direction: column;
      order: 2;
      transition: all 0.15s ease-in-out;
      position: absolute;
      background: transparent;
      align-items: flex-start;
      background-color: transparent;
      pointer-events: none;
      z-index: 20;
      opacity: -10;
      height: calc(100vh - 64px);
      overflow: scroll;

      ${isExpanded &&
      css`
        visibility: visible;
        pointer-events: auto;
        background-color: white;
        box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        margin-top: 0;
      `}
    `)}
`

export const linkStyles = css`
  margin-right: 24px;
  text-align: center;
  padding-top: 3px;
  white-space: pre;

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    width: 100%;
    display: block;
    padding-top: 0px;
  `)}
`

export const Link = styled.a`
  ${linkStyles}
`

export const LogInButton = styled(Link)`
  ${bodySemiboldStyle({ noResponsive: true })}
  pointer-events: pointer;
  background-color: ${Color.GRAY_1};
  border-radius: 12px;
  color: black;
  padding: 9px 16px;
  transition: all 0.2s ease-in-out;
  margin-right: 16px;

  &:hover {
    background-color: ${Color.GRAY_2};
    color: black;
  }

  ${minScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    margin-right: 16px;
  `)}

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    ${bodyMediumStyle({ noResponsive: true })}
    padding: 9px 16px;
    order: 2;
    margin-top: 12px;
  `)}
`

export const MenuBtn = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 32px;

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    display: flex;
  `)}

  ${maxScreenWidth(400)(css`
    margin-left: 12px;
  `)}
`

export const MenuBtnBurger = styled.div<{ isOpen: boolean }>`
  display: none;
  width: 20px;
  height: 2px;
  background: black;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: black;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }

  &::before {
    transform: translateY(-7px);
  }

  &:after {
    transform: translateY(7px);
  }

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    display: block;
  `)}

  ${({ isOpen }) =>
    !!isOpen &&
    css`
      background: transparent;

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(135deg);
      }
    `}
`
