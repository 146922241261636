import { Link as RouterNavLink } from 'gatsby'
import styled, { css } from 'styled-components'

import { maxScreenWidth, minScreenWidth } from '../../../mixins'
import { Color } from '../../../theme'
import {
  bodyMediumStyle,
  bodyRegularStyle,
  bodySemiboldStyle,
} from '../../../typography'
import { COLLAPSED_MENU_MAX_WIDTH, linkStyles } from '../styled'

import _Chevron from './Chevron.inline.svg'

// @ts-ignore
export const Chevron = styled(_Chevron)<{ pointUp: boolean }>`
  display: inline-block;
  margin-top: -24px;
  position: relative;
  top: 7px;
  transition: all 200ms ease;

  a:hover & {
    transform: rotate(-180deg);
  }

  ${({ pointUp }) =>
    maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
      padding: 0px 20px;
      width: 64px;
      height: 50px;
      margin-top: unset;
      top: unset;
      transform: rotate(${pointUp ? '-180' : '0'}deg) !important;
    `)}
`
export const NavLink = styled(RouterNavLink)<{ isMobileOnly?: boolean }>`
  ${linkStyles}

  ${({ isMobileOnly }) =>
    !!isMobileOnly &&
    minScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
      display: none;
    `)}
`
export const Link = styled.a`
  ${linkStyles}
`

const SharedTitleStyles = css`
  padding-left: 32px;
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  width: 100%;
`

export const LinkText = styled.p`
  ${bodyRegularStyle({ noResponsive: true })}

  :hover {
    color: ${Color.PURPLE_5};
    svg {
      stroke: ${Color.PURPLE_5};
    }
  }

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    ${bodyMediumStyle({ noResponsive: true })}

    ${SharedTitleStyles}
    box-shadow: inset 0px -1px 0px ${Color.GRAY_2};

    a a & {
      ${bodyRegularStyle({ noResponsive: true })}
      background: ${Color.GRAY_1};
      padding-left: 32px;
      padding-right: 4px;
    }
  `)}
`

export const MegaMenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  padding: 0 12px;

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    padding: 0px;
    width: 100%;
  `)}
`

export const MegaMenuTitle = styled.p`
  ${bodySemiboldStyle({ noResponsive: true })}
  text-align: left;
  margin-bottom: 16px;
  padding: 8px 0;

  ${maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
    ${bodyMediumStyle({ noResponsive: true })}
    background: ${Color.GRAY_1};
    ${SharedTitleStyles}
  `)}
`

const SUB_NAV_MARGIN_TOP = '8px'

export const SubNav = styled.div<{
  isExpanded: boolean
  $isMegaMenu?: boolean
}>`

  ${({ $isMegaMenu = false }) =>
    minScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
      flex-direction: ${$isMegaMenu ? 'row' : 'column'};
      padding: ${$isMegaMenu ? '20px 20px 40px' : '18px 12px 20px 24px'};
      align-items: flex-start;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      position: absolute;
      left: ${$isMegaMenu ? '-280px' : '-24px'};
      margin-top: ${SUB_NAV_MARGIN_TOP};
      display: flex;
      background-color: white;
      align-items: stretch;
      transition: all 200ms ease;
      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;

      *:hover > & {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }

      /* Invisible but to bridge trigger and sub menu */
      &:before {
        content: '';
        height: ${SUB_NAV_MARGIN_TOP};
        left: 0;
        right: 0;
        position: absolute;
        top: -${SUB_NAV_MARGIN_TOP};
      }

      ${LinkText} {
        text-align: left;
      }

      a + a {
        margin-top: ${$isMegaMenu ? '13px' : '11px'};
      }
    `)}}

  ${({ isExpanded }) =>
    maxScreenWidth(COLLAPSED_MENU_MAX_WIDTH)(css`
      ${!isExpanded &&
      css`
        display: none;
      `}
    `)}
`
