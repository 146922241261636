import styled, { css } from 'styled-components'

import {
  minScreenWidth,
  mobile,
  tablet,
  screenSize1,
  screenSize2,
  screenSize3,
} from '../../components/mixins'

export const Container = styled.div<{
  paddingTop?: boolean
  paddingBottom?: boolean
  close?: boolean
  column?: boolean
  duo?: boolean
  trio?: boolean
}>`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;

  ${({ paddingTop, close }) =>
    !!paddingTop &&
    css`
      padding-top: ${close ? 80 : 128}px;

      ${tablet(css`
        padding-top: 72px;
      `)}
    `}

  ${({ paddingBottom, close }) =>
    !!paddingBottom &&
    css`
      padding-bottom: ${close ? 80 : 128}px;

      ${tablet(css`
        padding-bottom: 72px;
      `)}
    `}

  ${({ column }) =>
    !!column &&
    css`
      flex-direction: column;
    `}

  ${screenSize1(css`
    max-width: 1100px;
  `)}

  ${screenSize2(css`
    max-width: 900px;
  `)}

  ${tablet(css`
    max-width: 700px;
  `)}

  ${screenSize3(css`
    max-width: 500px;
  `)}

  ${mobile(css`
    flex-direction: column;
    max-width: 327px;
  `)}

  ${({ duo, trio }) =>
    !!(duo || trio) &&
    css`
      display: block;

      > * {
        display: inline-block;
        width: ${trio ? 30 : 50}%;
        vertical-align: middle;

        ${tablet(css`
          width: 100%;
          text-align: center;
          ${!!trio &&
          css`
            display: flex;
            flex-direction: column;
          `}
        `)}
      }

      ${tablet(css`
        display: flex;
        flex-direction: column;
      `)}
    `}
`

export const Spacer = styled.div`
  flex: 1 0 0;
`

export const Centered = styled.div<{ large?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;

  ${({ large }) =>
    !large &&
    minScreenWidth(620)(css`
      max-width: 640px;
    `)}
`
