import { graphql, Link, StaticQuery } from 'gatsby'
import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { SignUpLinkQuery } from '../../graphql-types'
import { NEW_SIGNUP_PATH } from '../constants'

import { Button } from './forms'

export const linkQuery = graphql`
  query SignUpLink {
    link: contentfulLink(
      contentful_id: { eq: "HxkQtzA3rOoKO2MEho5h1" }
      node_locale: { eq: "en" }
    ) {
      ...Link
    }
  }
`

const Outer = styled.div`
  width: 100%;
`

export const SignUpButton = styled(Button)<{ noShadow?: boolean }>`
  ${({ noShadow }) =>
    !!noShadow &&
    css`
      box-shadow: none;
    `}
`

type Props = ComponentProps<typeof Outer> & {
  noShadow?: boolean
  buttonId?: string
}

const SignUpWidget = ({ noShadow, buttonId, ...props }: Props) => (
  <StaticQuery
    query={linkQuery}
    render={(data: SignUpLinkQuery) => {
      const link = data.link
      return (
        <Outer {...props}>
          <Link to={link?.url || NEW_SIGNUP_PATH}>
            <SignUpButton
              title={link?.label || 'Get Started'}
              isValid
              noShadow={noShadow}
              id={buttonId}
            />
          </Link>
        </Outer>
      )
    }}
  />
)

export default SignUpWidget
