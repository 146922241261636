import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import type { FooterQuery } from '../../../../graphql-types'
import Double from '../../../images/Double.inline.svg'
import Facebook from '../../../images/Facebook-black.inline.svg'
import Instagram from '../../../images/Instagram-black.inline.svg'
import LinkedIn from '../../../images/LinkedIn-black.inline.svg'
import Logo from '../../../images/Logo.inline.svg'
import { BodyRegular } from '../../typography'
import type { Linkable } from '../../utils'
import { getLinkProps } from '../../utils'

import {
  FooterContainer,
  Placeholder,
  Outer,
  FooterLeft,
  FooterRight,
  Social,
  Copyright,
  NavSection,
  LogoLink,
  Link,
  NavLink,
  NavTitle,
} from './styled'

export const query = graphql`
  query Footer {
    footer: contentfulFooter(node_locale: { eq: "en" }) {
      navigations {
        ...Navigation
      }
    }
  }
`

interface NavigationLinkProps {
  link: Linkable
  id?: string
}

const NavigationLink = ({ link, id }: NavigationLinkProps) => {
  const { label, isPath, href, ...linkProps } = getLinkProps(link) || {}
  if (!href) {
    return null
  }

  if (!isPath) {
    return (
      <Link href={href} {...linkProps} id={id}>
        <BodyRegular>{label}</BodyRegular>
      </Link>
    )
  } else {
    return (
      <NavLink to={href} id={id}>
        <BodyRegular noResponsive>{label}</BodyRegular>
      </NavLink>
    )
  }
}

const Footer = () => {
  const data = useStaticQuery<FooterQuery>(query)

  const footer = data?.footer

  return (
    <FooterContainer>
      <Placeholder />
      <Outer>
        <FooterLeft>
          <LogoLink to={'/'} id={'footer-logo'}>
            <Logo />
            <Double />
          </LogoLink>
          <Copyright>
            <BodyRegular>{`© ${new Date().getFullYear()} Double Atelier`}</BodyRegular>
          </Copyright>
          <Social>
            <Link
              href={'https://www.linkedin.com/company/withdouble'}
              id={'footer-linkedin'}
            >
              <LinkedIn />
            </Link>
            <Link
              href={'https://www.instagram.com/imwithdouble/'}
              id={'footer-instagram'}
            >
              <Instagram />
            </Link>
            <Link
              href={'https://www.facebook.com/imwithdouble'}
              id={'footer-facebook'}
            >
              <Facebook />
            </Link>
          </Social>
        </FooterLeft>

        <FooterRight>
          {footer?.navigations?.map(
            (navigation) =>
              !!navigation && (
                <NavSection key={navigation.title}>
                  <NavTitle noResponsive>{navigation.title}</NavTitle>
                  {navigation.links?.map(
                    (link, index) =>
                      !!link && (
                        <NavigationLink
                          key={index}
                          link={link}
                          id={'footer-link'}
                        />
                      ),
                  )}
                </NavSection>
              ),
          )}
        </FooterRight>
      </Outer>
    </FooterContainer>
  )
}

export default Footer
