import { graphql, useStaticQuery } from 'gatsby'
import React, { useState, useCallback } from 'react'

import type { TopNavQuery } from '../../../../graphql-types'
import SignUpWidget from '../../SignUpWidget'

import NavigationLink from './NavigationLink'
import { Ctas, Pages, MenuBtn, MenuBtnBurger, LogInButton } from './styled'

export const query = graphql`
  query TopNav {
    nav: contentfulNavigation(
      contentful_id: { eq: "4VVCLNhO7wKdebxK8aEHiU" }
      node_locale: { eq: "en" }
    ) {
      ...Navigation
    }
  }
`

interface Props {
  withGetStarted?: boolean
  withNavLinks?: boolean
}

const TopNav = ({
  withGetStarted = true,
  withNavLinks = true,
}: Props): JSX.Element => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery<TopNavQuery>(query)
  const toggle = useCallback(() => setMenuOpen((open) => !open), [setMenuOpen])
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen])

  return (
    <>
      <Pages isExpanded={isMenuOpen} id={'topnav-links'}>
        {!!withNavLinks && (
          <>
            {data.nav?.links?.map(
              (link, index) =>
                !!link && (
                  <NavigationLink
                    id={'topnav-link'}
                    link={link}
                    key={index}
                    onClick={closeMenu}
                  />
                ),
            )}

            <Ctas id={'topnav-ctas'}>
              <LogInButton
                target={'_blank'}
                rel={'noopener noreferrer'}
                href={'/login'}
                onClick={closeMenu}
                id={'topnav-login'}
              >
                {'Log in'}
              </LogInButton>

              {!!withGetStarted && (
                <SignUpWidget
                  withEmailInput={false}
                  noShadow
                  buttonId={'topnav-cta'}
                />
              )}
            </Ctas>
          </>
        )}
      </Pages>
      {!!withNavLinks && (
        <MenuBtn onClick={toggle}>
          <MenuBtnBurger isOpen={isMenuOpen} />
        </MenuBtn>
      )}
    </>
  )
}

export default TopNav
