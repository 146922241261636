import type { ComponentProps } from 'react'
import React, { forwardRef } from 'react'

import { BodySemibold } from '../typography'
import { validateEmail } from '../utils'

import {
  ButtonEl,
  Outer,
  SelectLabel,
  InputEl,
  TextareaEl,
  ErrorText,
} from './styled'

type ButtonProps = ComponentProps<typeof ButtonEl> & {
  title: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isValid, title, disabled, ...props }, ref) => (
    <ButtonEl
      ref={ref}
      disabled={disabled || !isValid}
      isValid={isValid}
      {...props}
    >
      <BodySemibold noColor>
        <span className={'button-label'}>{title}</span>
      </BodySemibold>
    </ButtonEl>
  ),
)

Button.displayName = 'Button'

interface InputProps extends ComponentProps<'input'> {
  onValid?: (value: boolean) => void
  validate?: (value: string) => boolean
  onChangeValue?: (value: string) => void
  errorMessage?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { onValid, validate, onChangeValue, errorMessage, ...props },
  ref,
) {
  return (
    <Outer>
      <InputEl
        // @ts-ignore
        ref={ref}
        type={'text'}
        onKeyUp={({ currentTarget }) => {
          if (validate && onValid) {
            onValid(validate(currentTarget.value))
          }
        }}
        onChange={({ target: { value } }) =>
          onChangeValue && onChangeValue(value)
        }
        heap-ignore={'true'}
        {...props}
      />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Outer>
  )
})

Input.displayName = 'Input'

export const EmailInput = forwardRef<HTMLInputElement, InputProps>(
  function EmailInput(props, ref) {
    return (
      <Input
        // @ts-ignore
        ref={ref}
        type={'email'}
        name={'email'}
        placeholder={'Enter your work email'}
        validate={(value) => validateEmail(value)}
        {...props}
      />
    )
  },
)
EmailInput.displayName = 'EmailInput'

export const MultilineTextInput = forwardRef<
  HTMLTextAreaElement,
  ComponentProps<'textarea'>
>(function MultilineTextInput(props, ref) {
  return (
    <TextareaEl
      // @ts-ignore
      ref={ref}
      heap-ignore={'true'}
      {...props}
    />
  )
})
MultilineTextInput.displayName = 'MultilineTextInput'

interface RadioInputProps
  extends ComponentProps<'label'>,
    Pick<
      ComponentProps<'input'>,
      'name' | 'value' | 'disabled' | 'required' | 'checked'
    > {
  onChangeValue?: (value: string) => void
}

export const RadioInput = ({
  name,
  value,
  onChangeValue,
  disabled,
  required,
  checked,
  children,
  ...props
}: RadioInputProps) => (
  <label {...props}>
    <input
      type={'radio'}
      name={name}
      value={value}
      disabled={disabled}
      required={required}
      checked={checked}
      onChange={({ target: { value } }) =>
        onChangeValue && onChangeValue(value)
      }
      style={{
        position: 'absolute',
        opacity: 0,
      }}
    />
    {children}
  </label>
)

interface CheckboxInputProps
  extends ComponentProps<'label'>,
    Pick<ComponentProps<'input'>, 'name' | 'value' | 'disabled' | 'required'> {
  onChangeValue?: (value: boolean) => void
}

export const CheckboxInput = ({
  id,
  name,
  value,
  onChangeValue,
  disabled,
  required,
  children,
  ...props
}: CheckboxInputProps) => (
  <label {...props}>
    <input
      id={id}
      type={'checkbox'}
      name={name}
      disabled={disabled}
      required={required}
      onChange={({ target: { checked } }) =>
        onChangeValue && onChangeValue(!!checked)
      }
      style={{
        position: 'absolute',
        opacity: 0,
      }}
      {...(typeof value === 'boolean' ? { value } : null)}
    />
    {children}
  </label>
)

interface SelectInputProps extends ComponentProps<'select'> {
  onChangeValue?: (value: string) => void
  options: {
    name?: string
    value: string
  }[]
}

export const SelectInput = ({
  name,
  placeholder,
  options,
  defaultValue,
  className,
  onChangeValue,
  style,
  ...props
}: SelectInputProps) => (
  <SelectLabel className={className} style={style}>
    <select
      defaultValue={defaultValue || ''}
      name={name}
      onChange={(e) => onChangeValue && onChangeValue(e.target.value)}
      {...props}
    >
      <option value={''} disabled>
        {placeholder}
      </option>
      {options.map(({ name, value }) => (
        <option key={value} value={value}>
          {name ?? value}
        </option>
      ))}
    </select>
  </SelectLabel>
)

interface SelectInputWithSeparatorProps extends ComponentProps<'select'> {
  errorMessage?: string
  onChangeValue?: (value: string) => void
  primaryOptions: {
    name?: string
    value: string
  }[]
  secondaryOptions?: {
    name?: string
    value: string
  }[]
}

export const SelectInputWithSeparator = ({
  name,
  placeholder,
  primaryOptions,
  secondaryOptions,
  defaultValue,
  errorMessage,
  className,
  style,
  onChangeValue,
  ...props
}: SelectInputWithSeparatorProps) => (
  <>
    <SelectLabel className={className} style={style}>
      <select
        defaultValue={defaultValue || ''}
        name={name}
        onChange={(e) => onChangeValue && onChangeValue(e.target.value)}
        {...props}
      >
        <option value={''} disabled>
          {placeholder}
        </option>
        {primaryOptions.map(({ name, value }) => (
          <option key={value} value={value}>
            {name ?? value}
          </option>
        ))}
        {secondaryOptions && (
          <>
            <option disabled>{'---------------'}</option>
            {secondaryOptions.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </>
        )}
      </select>
    </SelectLabel>
    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
  </>
)

export function getFormValues<V extends Record<string, string>>({
  elements,
}: HTMLFormElement): V {
  const values: Record<string, string> = {}

  for (let index = 0; index < elements.length; index++) {
    const input = elements[index] as HTMLFormElement

    if (!input.name) {
      continue
    }
    if (!['INPUT', 'TEXTAREA', 'SELECT'].includes(input.nodeName)) {
      continue
    }

    if (input.type === 'radio' && !input.checked) {
      continue
    }

    if (input.type === 'checkbox' && !input.checked) {
      continue
    }

    values[input.name] = input.value as string
  }

  return values as V
}
