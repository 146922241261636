import { useMemo } from 'react'

import useQueryOrStorageParam from './useQueryOrStorageParam'

export const UTM_SOURCE_NAME = 'utm_source'
export const UTM_MEDIUM_NAME = 'utm_medium'
export const UTM_CAMPAIGN_NAME = 'utm_campaign'
export const UTM_TERM_NAME = 'utm_term'
export const UTM_CONTENT_NAME = 'utm_content'

export interface Utm {
  source?: string
  medium?: string
  campaign?: string
  term?: string
  content?: string
}

export default function useUtm(): Utm {
  // get each utm param and return a utm object
  const source = useQueryOrStorageParam(UTM_SOURCE_NAME, '', { persist: true })
  const medium = useQueryOrStorageParam(UTM_MEDIUM_NAME, '', { persist: true })
  const campaign = useQueryOrStorageParam(UTM_CAMPAIGN_NAME, '', {
    persist: true,
  })
  const term = useQueryOrStorageParam(UTM_TERM_NAME, '', { persist: true })
  const content = useQueryOrStorageParam(UTM_CONTENT_NAME, '', {
    persist: true,
  })

  return useMemo(
    () => ({
      source,
      medium,
      campaign,
      term,
      content,
    }),
    [campaign, content, medium, source, term],
  )
}
