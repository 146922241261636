import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import {
  Color,
  MOBILE_WIDTH,
  TABLET_WIDTH,
  SCREEN_SIZE_1_WIDTH,
  SCREEN_SIZE_2_WIDTH,
  SCREEN_SIZE_3_WIDTH,
} from './theme'

export const square = (size: number) => css`
  height: ${size}px;
  width: ${size}px;
`

export const disc = (size: number) => css`
  ${square(size)}
  border-radius: ${size}px;
`

export const minScreenWidth =
  (width: number) => (content: string | FlattenSimpleInterpolation) =>
    css`
      @media screen and (min-width: ${width}px) {
        ${content}
      }
    `

export const maxScreenWidth =
  (width: number) => (content: string | FlattenSimpleInterpolation) =>
    css`
      @media screen and (max-width: ${width}px) {
        ${content}
      }
    `

export const desktop = minScreenWidth(MOBILE_WIDTH)
export const mobile = maxScreenWidth(MOBILE_WIDTH)
export const tablet = maxScreenWidth(TABLET_WIDTH)
export const screenSize3 = maxScreenWidth(SCREEN_SIZE_3_WIDTH)
export const screenSize2 = maxScreenWidth(SCREEN_SIZE_2_WIDTH)
export const screenSize1 = maxScreenWidth(SCREEN_SIZE_1_WIDTH)

export const bodyGradient = (
  position: 'top' | 'bottom' = 'top',
  height = 0,
) => {
  const gradientSize = height || 400
  const gradientMobileSize = height || 320

  return css`
    ${position}: 0;

    position: relative;
    right: 0;
    left: 0;

    &::before,
    &::after {
      ${position}: -${gradientSize}px;

      position: absolute;
      right: 0;
      left: 0;
      height: ${gradientSize}px * 2;
      content: '';
      opacity: 0.14;
      z-index: -1;
    }

    /* Flamingo gradient */
    &::before {
      background: radial-gradient(
        ellipse ${gradientSize}px*2 ${gradientSize}px at 20%,
        ${Color.FLAMINGO_5} 0%,
        rgba(255, 110, 102, 0) 100%
      );
    }

    /* Purple gradient */
    &::after {
      transform: matrix(-1, 0, 0, 1, 0, 0);
      background: radial-gradient(
        ellipse ${gradientSize}px*2 ${gradientSize}px at 20%,
        ${Color.PURPLE_5} 0%,
        rgba(119, 119, 230, 0) 100%
      );
    }

    ${mobile(css`
      &::before,
      &::after {
        height: ${gradientMobileSize}px * 2;
        ${position}: -${gradientMobileSize}px;
      }

      &::before {
        background: radial-gradient(
          ${gradientMobileSize}px at 2% ${gradientMobileSize}px,
          ${Color.FLAMINGO_5} 0%,
          rgba(255, 110, 102, 0) 100%
        );
      }

      &::after {
        background: radial-gradient(
          ${gradientMobileSize}px at 2% ${gradientMobileSize}px,
          ${Color.PURPLE_5} 0%,
          rgba(119, 119, 230, 0) 100%
        );
      }
    `)}
  `
}
