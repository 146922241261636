import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { mobile } from '../mixins'
import { Color, BORDER_RADIUS } from '../theme'
import { bodyRegularStyle, captionRegularStyle } from '../typography'

export const ButtonEl = styled.button<{
  isValid?: boolean
  negative?: boolean
  wide?: boolean
}>`
  text-align: center;
  padding: 0px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  height: 48px;

  ${({ wide }) =>
    !!wide &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}

  ${({ isValid }) => {
    if (isValid) {
      return css`
        background: ${Color.PURPLE_5};
        color: ${Color.WHITE};
        position: relative;

        &:hover {
          background-color: ${Color.PURPLE_6};
        }
      `
    } else {
      return css`
        opacity: 0.5;
        cursor: default;
      `
    }
  }}

  ${({ negative }) =>
    !!negative &&
    css`
      background: ${Color.PURPLE_4};
    `}
`

export const SelectLabel = styled.label`
  display: flex;

  select {
    flex: 1;
    border-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    color: ${Color.BLACK};
    cursor: pointer;
    background: ${Color.GRAY_2};
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.01em;
    padding: 14px;
    padding-right: 36px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    transition: box-shadow 200ms ease, border-color 200ms ease;
    max-width: 100%;
  }

  &::after {
    content: '▾';
    position: absolute;
    top: 50%;
    right: 14px;
    line-height: 21px;
    margin-top: -11px;
  }
`

const inputAndTextareaStyle = css`
  ${bodyRegularStyle}
  font-weight: 500;
  padding: 14px !important;
  border-radius: ${BORDER_RADIUS}px;
  background: ${Color.GRAY_2};

  &::placeholder {
    color: ${Color.GRAY_6};
  }

  &:focus {
    background-color: white;
    box-shadow: inset 0 0 0 2px ${Color.PURPLE_4};
    -webkit-appearance: none;
  }

  &:focus + .button:not(.valid) {
    background: ${lighten(0.1, Color.PURPLE_5)};
  }

  // box-shadow doesn't work in mobile
  ${mobile(css`
    &:focus {
      background-color: white;
      border: 0.2px solid ${Color.PURPLE_4};
    }
  `)}
`

export const InputEl = styled.input`
  ${inputAndTextareaStyle}
  height: 48px;
`

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`
export const ErrorText = styled.div`
  ${captionRegularStyle}
  color: ${Color.FLAMINGO_6};
`

export const TextareaEl = styled.textarea`
  ${inputAndTextareaStyle}
`
