import { Link as NavLink } from 'gatsby'
import styled, { css } from 'styled-components'

import { mobile } from '../../mixins'
import Container from '../../modules/Container'
import { Color } from '../../theme'

export const Spacer = styled.div`
  flex: 1 0 0;
`

export const Outer = styled.nav<{ showShadow?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: background 0.2s ease-in-out;
  background: white;
  z-index: 35;
  padding: 12px 0;

  ${({ showShadow }) =>
    !!showShadow &&
    css`
      transition: box-shadow 0.3s, color 0.5s, background-color 0.5s;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    `}

  ${mobile(css`
    padding: 8px 0;
  `)}
`

export const Inner = styled(Container)`
  flex-direction: row;
  align-items: center;
  height: 48px;
  background-color: white;
  column-gap: 4px;

  svg + svg {
    margin-left: 14px;
  }
`

export const Placeholder = styled.div`
  display: block;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  height: 72px;
  z-index: -1;

  ${mobile(css`
    height: 64px;
  `)}
`

export const LogoLink = styled(NavLink)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.017em;
  display: flex;
  align-items: center;
  z-index: 30;

  svg {
    pointer-events: bounding-box;
    margin-left: -1px;
  }

  &:hover svg path {
    fill: ${Color.PURPLE_5};
  }

  span {
    margin: 12px;
  }
`
