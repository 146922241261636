import phoneNumber, { PhoneNumberFormat } from 'google-libphonenumber'
import Linkify from 'linkify-it'
import type { ReactNode } from 'react'
import React, { Fragment } from 'react'

import type {
  LinkFragment,
  NavigationLegacyPageFragment,
  NavigationModularPageFragment,
  NavigationPostFragment,
  NavigationJobFragment,
} from '../../graphql-types'
import countries from '../countries'

export const linkify = Linkify()

export type Linkable =
  | LinkFragment
  | NavigationLegacyPageFragment
  | NavigationModularPageFragment
  | NavigationPostFragment
  | NavigationJobFragment

export function getLinkProps(link: Linkable | null): null | {
  href: string
  label: string
  isPath: boolean
  target?: string
  rel?: string
} {
  if (!link) {
    return null
  }

  let label: string | null | undefined
  let url: string | null | undefined
  let path: string | null | undefined
  let target: string | undefined
  let rel: string | undefined

  switch (link.__typename) {
    case 'ContentfulLink':
      label = link.navigationLabel || link.label
      url = link.url
      if (link.newTab) {
        target = '_blank'
        rel = 'noopener noreferrer'
      }
      break
    case 'ContentfulPage':
      label = link.navigationLabel || link.title
      path = `/${link.slug}`
      break
    case 'ContentfulModularPage':
      label = link.navigationLabel || link.title
      path = `/${link.slug}`
      break
    case 'ContentfulBlogPost':
      label = link.title
      path = `/blog/${link.slug}`
      break
    case 'ContentfulJobListing':
      label = link.title
      path = `/jobs/${link.slug}`
      break
    default:
      break
  }

  if (label && url) {
    return {
      label,
      href: url,
      isPath: false,
      target,
      rel,
    }
  } else if (label && path) {
    return {
      href: path === '/home' ? '/' : path,
      label,
      isPath: true,
      target,
      rel,
    }
  }

  return null
}

export function renderTextWithLineBreaks(
  text: string,
  renderLine: (text: string) => string | ReactNode = (text) => text,
): ReactNode {
  return text.split('\n').map((line, index) => (
    <Fragment key={index}>
      {index > 0 && <br />}
      {renderLine(line)}
    </Fragment>
  ))
}

export function renderTextWithB(text: string): ReactNode {
  return text
    ?.split(/\*\*?/g)
    .map((word, index) => (
      <Fragment key={index}>{index % 2 === 1 ? <b>{word}</b> : word}</Fragment>
    ))
}

export function renderTextWithLineBreaksAndB(text: string): ReactNode {
  return renderTextWithLineBreaks(text, renderTextWithB)
}

type TrackEventProps =
  | Record<string, string>
  | {
      eventCallback: () => void
      eventTimeout: number
    }

export function trackEvent(eventName: string, props?: TrackEventProps): void {
  window.dataLayer // @ts-ignore
    ?.push?.({
      ...props,
      event: eventName,
    }) ??
    // Handy when dev and there is no datalayer
    (typeof props?.eventCallback === 'function' && props?.eventCallback())
}

const EMAIL_ADDRESS_REGEX =
  /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{2,}$/i
export function validateEmail(email: string): boolean {
  return EMAIL_ADDRESS_REGEX.test(email)
}

const DEFAULT_REGION_CODE =
  (typeof window !== 'undefined' ? window.navigator.languages : [])
    ?.map((l) => l.split('-')[1])
    .find(
      (languageCode) =>
        languageCode && countries.some(({ code }) => languageCode === code),
    )
    ?.toUpperCase() || 'US'

const phoneUtil = phoneNumber.PhoneNumberUtil.getInstance()
export function validatePhoneNumber(
  phoneNumber: string,
  countryCode?: string,
): boolean {
  try {
    const regionCode = countryCode || DEFAULT_REGION_CODE

    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(phoneNumber, regionCode),
      regionCode,
    )
  } catch (e) {
    // ignore error
    return false
  }
}

// Format number in the E164 format
export function formatPhoneNumber(
  phoneNumber: string,
  countryCode?: string,
): string | undefined {
  try {
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode || DEFAULT_REGION_CODE,
    )
    return phoneUtil.format(number, PhoneNumberFormat.E164)
  } catch (e) {
    // ignore error
    return undefined
  }
}

export const isZipCodeValid = (value: string): boolean =>
  /^[A-Z0-9\- ]*$/.test(value)
