import 'promise-polyfill/src/polyfill'
import type { ComponentProps } from 'react'
import { useMemo } from 'react'
import React from 'react'
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'

import isMobile from '../../utils/isMobile'
import { Color } from '../theme'

import Footer from './Footer'
import GlobalStyle from './GlobalStyles'
import Head from './Head'
import Header from './Header'
import TopNav from './TopNav'
import './global.css'

type Props = Pick<ComponentProps<'div'>, 'children'> & {
  head?: ComponentProps<typeof Head>
  nav?: false | ComponentProps<typeof TopNav>
  header?: ComponentProps<typeof Header>
  hideHeader?: boolean
  hideFooter?: boolean
  hideIntercom?: boolean
}

const INTERCOM_APP_ID = process.env.GATSBY_INTERCOM_APP_ID

const PageLayout = ({
  children,
  head,
  nav,
  header,
  hideHeader,
  hideFooter,
  hideIntercom,
}: Props) => {
  const showIntercom = useMemo(
    () => !hideIntercom && !isMobile && INTERCOM_APP_ID,
    [hideIntercom],
  )

  return (
    <LiveChatLoaderProvider
      providerKey={INTERCOM_APP_ID || ''}
      provider={'intercom'}
    >
      <div>
        <Head {...head} />
        <GlobalStyle />

        {!hideHeader && (
          <Header {...header}>
            {header?.children ? (
              header.children
            ) : nav !== false ? (
              <TopNav {...nav} />
            ) : null}
          </Header>
        )}

        <main>{children}</main>
        {showIntercom && <Intercom color={Color.PURPLE_5} />}
        {!hideFooter && <Footer />}
      </div>
    </LiveChatLoaderProvider>
  )
}

export default PageLayout
