import type { ComponentProps } from 'react'
import React, { useState, useCallback } from 'react'

import type { Navigation_SubFragment } from '../../../../../graphql-types'
import type { Linkable } from '../../../utils'
import { getLinkProps } from '../../../utils'

import {
  Link,
  Chevron,
  LinkText,
  MegaMenuColumn,
  MegaMenuTitle,
  NavLink,
  SubNav,
} from './styled'

type NavigationLinkProps = Partial<ComponentProps<typeof NavLink>> & {
  link: Linkable | Navigation_SubFragment
}

const NavigationLink = ({
  link: _link,
  onClick,
  ...props
}: NavigationLinkProps) => {
  const [isExpanded, setExpanded] = useState(true)
  let labelOverride: string | null | undefined
  let link: Linkable | null
  let subNavLinks: Navigation_SubFragment['links']

  const toggleExpand = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      setExpanded((v) => !v)
    },
    [setExpanded],
  )

  if (_link.__typename === 'ContentfulNavigation') {
    labelOverride = _link.title
    link = _link.primaryLink || null
    subNavLinks = _link.links || []
  } else {
    link = _link
  }

  // If all subNavLinks types are ContentfulNavigation then it is a MegaMenu
  const isMegaMenu = subNavLinks?.every(
    (subLink) => subLink?.__typename === 'ContentfulNavigation',
  )

  const { label, href, ...aProps } = getLinkProps(link) || {}
  if (!href && !isMegaMenu) {
    return null
  }

  const content = (
    <>
      <LinkText>
        {labelOverride || label}
        {!!(subNavLinks && subNavLinks.length > 0) && (
          <Chevron onClick={toggleExpand} pointUp={isExpanded} />
        )}
      </LinkText>
      {isMegaMenu ? (
        <SubNav isExpanded={isExpanded} $isMegaMenu>
          {subNavLinks?.map(
            (subSubNav, index) =>
              subSubNav?.__typename === 'ContentfulNavigation' && (
                <MegaMenuColumn key={index}>
                  <MegaMenuTitle>{subSubNav?.title}</MegaMenuTitle>
                  {subSubNav.links?.map(
                    (link, index) =>
                      !!link && (
                        <NavigationLink
                          link={link}
                          key={index}
                          onClick={onClick}
                        />
                      ),
                  )}
                </MegaMenuColumn>
              ),
          )}
        </SubNav>
      ) : (
        !!(subNavLinks && subNavLinks.length > 0) && (
          <SubNav isExpanded={isExpanded}>
            {subNavLinks?.map(
              (link, index) =>
                !!link && (
                  <NavigationLink link={link} key={index} onClick={onClick} />
                ),
            )}
          </SubNav>
        )
      )}
    </>
  )

  if (href?.startsWith('http') && !isMegaMenu) {
    return (
      <Link
        {...props}
        onClick={(event: MouseEvent) => {
          event.stopPropagation()
          onClick(event)
        }}
        href={href}
        {...aProps}
      >
        {content}
      </Link>
    )
  }

  return (
    <NavLink
      {...props}
      onClick={(e: MouseEvent) => {
        e.stopPropagation()
        onClick(e)
      }}
      to={href}
    >
      {content}
    </NavLink>
  )
}

export default NavigationLink
