import type { ComponentProps } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import useGclid from '../../hooks/useGclid'
import useUtm from '../../hooks/useUtm'

const FAVICON = '/favicon/favicon.ico'
const CANONICAL_ORIGIN = 'https://withdouble.com'

const getImageAbsoluteUrl = (
  imageUrl: string | undefined,
): string | undefined =>
  imageUrl?.startsWith('http')
    ? imageUrl
    : imageUrl?.startsWith('//')
    ? 'https:' + imageUrl
    : imageUrl &&
      CANONICAL_ORIGIN + (imageUrl.startsWith('/') ? '' : '/') + imageUrl

type MetaProps = Omit<ComponentProps<'meta'>, 'content'> & {
  content: string | undefined | null
}

type Props = Pick<ComponentProps<'div'>, 'children'> & {
  title?: string | null
  description?: string | null
  websiteUrl?: string | null
  image?: string | null
  squareImage?: string | null
  openGraphType?: 'article' | 'website'
  metas?: MetaProps[]
}

const Head = ({
  title,
  description,
  websiteUrl: _websiteUrl,
  image,
  squareImage,
  openGraphType,
  metas,
  children,
}: Props) => {
  openGraphType = openGraphType || 'website'
  image = image || '/social-daas.png'
  squareImage = squareImage || '/social-daas-square.png'

  const [websiteUrl, setWebsiteUrl] = useState<string | null | undefined>(
    _websiteUrl,
  )

  useEffect(() => {
    if (!websiteUrl) {
      const canonicalUrl =
        `${CANONICAL_ORIGIN}${window.location.pathname}`.replace(/\/$/, '')
      setWebsiteUrl(_websiteUrl || canonicalUrl)
    }
  }, [_websiteUrl, websiteUrl, setWebsiteUrl])

  // Read and save Google Click ID
  // https://double.height.app/T-2711
  useGclid()
  useUtm()

  return (
    <Helmet>
      <meta httpEquiv={'Content-Type'} content={'text/html; charset=utf-8'} />
      <meta
        name={'facebook-domain-verification'}
        content={'26svdvmz940k0b36l3ni8jv3ekcahf'}
      />
      <meta name={'theme-color'} content={'#ffffff'} />
      <meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
      <link rel={'shortcut icon'} href={FAVICON} />
      <link rel={'icon'} href={FAVICON} />
      {/* <link rel={'canonical'} href={`${CANONICAL_ORIGIN}${window.location.pathname}`} /> */}

      {title && [
        <title key={'title'}>{`${title} - Double`}</title>,
        <meta key={'ogtitle'} property={'og:title'} content={title} />,
        // @ts-ignore
        <meta key={'twtitle'} name={'twitter:title'} value={title} />,
      ]}

      {description && [
        <meta key={'desc'} name={'description'} content={description} />,
        <meta
          key={'ogdesc'}
          property={'og:description'}
          content={description}
        />,
        // @ts-ignore
        <meta
          key={'twdesc'}
          name={'twitter:description'}
          value={description}
        />,
      ]}

      {websiteUrl && [
        <meta key={'ogurl'} property={'og:url'} content={websiteUrl} />,
        // @ts-ignore
        <meta
          key={'twurl'}
          name={'twitter:url'}
          value={websiteUrl}
          content={websiteUrl}
        />,
      ]}

      {(image || squareImage) && [
        <meta
          key={'ogimage'}
          property={'og:image'}
          content={getImageAbsoluteUrl(image || squareImage)}
        />,
        // Twitter image must be an absolute URL, because they suck
        <meta
          key={'twimage'}
          name={'twitter:image'}
          content={getImageAbsoluteUrl(squareImage || image)}
        />,
      ]}

      {!!openGraphType && [
        <meta key={'ogtype'} property={'og:type'} content={openGraphType} />,
      ]}

      {metas?.map(
        ({ content, ...metaProps }, index) =>
          !!content && (
            <meta
              key={`additionalmeta-${index}`}
              {...metaProps}
              content={content}
            />
          ),
      )}

      {children}
    </Helmet>
  )
}

export default Head
