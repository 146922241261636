import { Link as _NavLink } from 'gatsby'
import styled, { css } from 'styled-components'

import { mobile, screenSize3 } from '../../mixins'
import Container from '../../modules/Container'
import { Color } from '../../theme'
import { BodySemibold } from '../../typography'

export const FooterContainer = styled.footer`
  border-top: solid 1px ${Color.GRAY_2};
`

export const Outer = styled(Container)`
  height: 408px;

  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: flex-start;
  padding-top: 48px;
  justify-content: space-between;

  ${screenSize3(css`
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: flex-end;
    height: 575px;
  `)}

  ${mobile(css`
    height: 836px;
  `)}
`

export const Placeholder = styled(Outer)`
  position: unset;
`

const linkStyle = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${Color.PURPLE_5};
  }

  :global(.active) & {
    color: ${Color.PURPLE_6};
  }

  svg {
    pointer-events: bounding-box;
  }

  svg + svg {
    margin-left: 14px;
  }

  &:hover svg path {
    fill: ${Color.PURPLE_5};
  }
`

export const Link = styled.a`
  ${linkStyle}
`

export const NavLink = styled(_NavLink)`
  ${linkStyle}
`

export const LogoLink = styled(NavLink)`
  margin-bottom: 18px;
`

export const Social = styled.div``

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding-right: 64px;

  ${Social} {
    display: flex;
    flex-direction: row;
    color: ${Color.BLACK};
    * + * {
      margin-left: 16px;
    }
  }
`

export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;

  ${screenSize3(css`
    flex: 0 0 auto;
    justify-content: flex-start;
  `)}

  ${mobile(css`
    flex-wrap: wrap;
  `)}
`

export const NavSection = styled.div`
  flex: 0 1 198px;
  margin-bottom: 40px;
  padding-right: 32px;

  ${Link},
  ${NavLink} {
    margin-bottom: 20px;
  }
  ${mobile(css`
    flex: 1 0 50%;
  `)}
`

export const Copyright = styled.div`
  color: ${Color.BLACK};
  margin-bottom: 32px;
`

export const NavTitle = styled(BodySemibold)`
  margin-bottom: 28px;
`
