import useQueryOrStorageParam from './useQueryOrStorageParam'

export const GCLID_PARAM_NAME = 'gclid'

export default function useGclid(): string {
  const gclid = useQueryOrStorageParam(GCLID_PARAM_NAME, '', { persist: true })
  // @ts-ignore
  if (typeof window !== 'undefined' && !!window.Intercom) {
    // @ts-expect-error
    window.Intercom('update', { gclid })
  }
  return gclid
}
