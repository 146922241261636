import { useState, useEffect } from 'react'

export function setStorageParam(
  paramName: string,
  value: string,
  persist = false,
): void {
  try {
    window.sessionStorage.setItem(paramName, value)
  } catch (error) {
    console.error('Failed to write param into session storage', error)
  }

  try {
    if (persist) {
      window.localStorage.setItem(paramName, value)
    }
  } catch (error) {
    console.error('Failed to write param into local storage', error)
  }
}

type Options = {
  writeToSession?: boolean
  persist?: boolean
}

export default function useQueryOrStorageParam(
  paramName: string,
  defaultValue = '',
  options: Options = {},
): string | typeof defaultValue {
  const { writeToSession, persist } = { ...options }
  const [value, setValue] = useState<string | typeof defaultValue>(defaultValue)

  useEffect(() => {
    try {
      if (window.location.search) {
        // disable lint since we import a URLSearchParams polyfill for IE
        const params = new URLSearchParams(window.location.search) // eslint-disable-line
        const queryValue = params.get(paramName)
        if (queryValue) {
          if (writeToSession || persist) {
            setStorageParam(paramName, queryValue, persist)
          }
          return setValue(queryValue)
        }
      }
    } catch (error) {
      console.error('Failed to read param from URL query', error)
    }

    try {
      const storageValue =
        window.sessionStorage.getItem(paramName) ||
        window.localStorage.getItem(paramName)
      if (storageValue) {
        setValue(storageValue)
        return
      }
    } catch (error) {
      console.error('Failed to read param from storage', error)
    }
  }, [setValue])

  return value
}
