import { lighten } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { Color, BORDER_RADIUS } from '../theme'

export default createGlobalStyle`
  body {
    background: ${Color.WHITE};
    color: ${Color.BLACK};
    font-family: 'Indivisible-Regular', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
  }

  #root {
    min-height: 100vh;
    overflow: hidden;
  }

  a {
    transition: color .2s ease-in-out;
    color: ${Color.BLACK};
    &:hover {
      color: #9393A3;
    }
  }

  img {
    user-select: none;
  }

  ::selection {
    background-color: ${lighten(0.24, Color.PURPLE_5)};
  }
  .unselectable {
    user-select: none;
  }
  .center {
    margin: auto;
    text-align: center;
  }
  .txt-gradient {
    background: -webkit-linear-gradient(0deg, ${Color.FLAMINGO_5}, ${
  Color.PURPLE_5
});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* buttons */

  button,
  .button,
  .button-secondary {
    transition: all .2s ease-in-out;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }

  button,
  .button {
    padding: 14px 16px;
    border-radius: ${BORDER_RADIUS}px;
    background: ${Color.PURPLE_5};
    color: ${Color.WHITE};
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .button-secondary {
    padding: 14px 24px;
    border: 2px solid ${lighten(0.1, '#9393A3')};
    border-radius: 100px;
    background: ${Color.WHITE};
    color: #9393A3;

    &:hover {
      background: ${lighten(0.1, '#9393A3')};
      color: ${Color.WHITE};
    }
  }

`
