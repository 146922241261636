export const GRID = 8

export const BORDER_RADIUS = 12

export const WIDTH_PERCENT = 96
export const BODY_MAX_WIDTH = 980
export const BODY_INNER_WIDTH = 816
export const MARGIN_HEIGHT = 40
export const HEADER_HEIGHT = 64
export const FOOTER_HEIGHT = (mobile?: boolean): number => (mobile ? 155 : 200)

export const SCREEN_SIZE_1_WIDTH = 1360
export const SCREEN_SIZE_2_WIDTH = 1200
export const SCREEN_SIZE_3_WIDTH = 800
export const TABLET_WIDTH = 1000
export const MOBILE_WIDTH = 530
export const MOBILE_MARGIN = 24

export enum Color {
  BORDER = 'rgba(46,50,71,.08)',

  BLACK = '#161618',
  WHITE = '#FFFFFF',

  GRAY_1 = '#F7F8F9',
  GRAY_2 = '#ECEDF0',
  GRAY_3 = '#E1E2E7',
  GRAY_4 = '#D4D6DD',
  GRAY_5 = '#C1C4CD',
  GRAY_6 = '#818691',
  GRAY_7 = '#4C4E55',

  PURPLE_1 = '#ECE9FD',
  PURPLE_2 = '#D0C9FB',
  PURPLE_3 = '#B0A6F9',
  PURPLE_4 = '#9082F7',
  PURPLE_5 = '#644BFF',
  PURPLE_6 = '#581BF5',

  FLAMINGO_1 = '#FCEFEE',
  FLAMINGO_2 = '#F8D6D2',
  FLAMINGO_3 = '#F4BAB5',
  FLAMINGO_4 = '#F19F97',
  FLAMINGO_5 = '#FF6E66',
  FLAMINGO_6 = '#EB5144',

  ORANGE_1 = '#FAEEE8',
  ORANGE_2 = '#F2D3C5',
  ORANGE_3 = '#EAB69F',
  ORANGE_4 = '#E29879',
  ORANGE_5 = '#E66636',
  ORANGE_6 = '#C54628',

  YELLOW_1 = '#FDF6EB',
  YELLOW_2 = '#F9E9CB',
  YELLOW_3 = '#F5D9A9',
  YELLOW_4 = '#F0CA87',
  YELLOW_5 = '#F4B144',
  YELLOW_6 = '#DF8D3C',

  GREEN_1 = '#EDF7EE',
  GREEN_2 = '#D2EAD5',
  GREEN_3 = '#B5DDB9',
  GREEN_4 = '#98CE9E',
  GREEN_5 = '#54BC6D',
  GREEN_6 = '#4D9549',

  BLUE_1 = '#EAF4FD',
  BLUE_2 = '#BFDEF8',
  BLUE_3 = '#99CBF4',
  BLUE_4 = '#75B8F0',
  BLUE_5 = '#2A92E8',
  BLUE_6 = '#146EB8',

  VIOLET_1 = '#F5E9FA',
  VIOLET_2 = '#DEBAEF',
  VIOLET_3 = '#CD97E6',
  VIOLET_4 = '#BD75DE',
  VIOLET_5 = '#9727CC',
  VIOLET_6 = '#712097',

  ROSE_1 = '#FCF0F7',
  ROSE_2 = '#F4CEE7',
  ROSE_3 = '#EEB5DB',
  ROSE_4 = '#E99CCF',
  ROSE_5 = '#DC71BA',
  ROSE_6 = '#B53C8E',
}
